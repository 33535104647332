import {
  TextField as TextFieldBravas,
  TextFieldProps,
  dspxToRem,
  dsbreakpoints,
} from '@bravas/design-system';
import React from 'react';

export const labelProps = {
  sx: {
    textTransform: 'uppercase',
    transform: 'none',
    color: '#000000',
    pl: 0.5,
    '&:not(.Mui-error).Mui-focused': {
      color: '#3F47E5',
    },
    position: 'relative',
    lineHeight: '1.66',
    fontWeight: 600,

    fontSize: dspxToRem(12),
    [`@media (min-width:${dsbreakpoints.desktop}px)`]: {
      fontSize: dspxToRem(13),
    },
  },
};
export default function TextField(
  props: TextFieldProps & {
    ref?: React.ForwardedRef<HTMLDivElement> | undefined;
  }
) {
  return <TextFieldBravas InputLabelProps={labelProps} {...props} />;
}
TextField.defaultProps = {
  ref: undefined,
};
