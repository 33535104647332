import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';
import { useGetEnvironnements } from 'api/subscription';
import DataPrivacy from 'components/DataPrivacy/DataPrivacy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CustomerForm from './CustomerForm';

export default function Customer() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const searchDomain = searchParams.get('domain');
  const [domain, setDomain] = useState('');

  if (!searchDomain) {
    window.location.replace('https://bravas.io');
  }

  const { data: environnements, isLoading: environnementIsLoading } =
    useGetEnvironnements();

  useEffect(() => {
    if (!environnementIsLoading && searchDomain) {
      if (
        !environnements?.environnements.find((elt) => elt.name === searchDomain)
      ) {
        window.location.replace('https://bravas.io');
      } else {
        setDomain(searchDomain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environnementIsLoading]);

  return (
    <Stack spacing={4}>
      <Box
        display="flex"
        justifyContent="left"
        textAlign="left"
        marginLeft={0}
        marginBottom={1}
      >
        <Typography variant="h1">{t('common.helloTitle')}</Typography>
      </Box>
      {environnementIsLoading && <CircularProgress />}
      {!environnementIsLoading && domain.length > 0 && (
        <CustomerForm domain={domain} />
      )}
      <DataPrivacy />
    </Stack>
  );
}
