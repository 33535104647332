import { SCIMErrorSchema } from '@bravas/js-commons-web';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// eslint-disable-next-line no-restricted-imports
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Layout from 'Layout';
import { AxiosError } from 'axios';
import sectionRoutes from 'pages/routes';
import { useCallback, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import theme from 'theme';

function mapToRoutes(routes: typeof sectionRoutes) {
  return (
    <>
      {Object.entries(routes)
        .map(([, sRoutes]) => {
          return sRoutes.flatMap((r) => {
            if (r.component !== undefined) {
              return [
                <Route key={r.path} element={<r.component />} path={r.path} />,
              ];
            }
            return [];
          });
        })
        .flat()}
    </>
  );
}

function App() {
  const [snackbarOpened, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const onError = useCallback((error: unknown) => {
    setSnackbarOpen(true);
    if (error instanceof AxiosError) {
      const scimError = SCIMErrorSchema.safeParse(error.response?.data);
      setErrorMsg(scimError.success ? scimError.data.detail : error.message);
    }
  }, []);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError,
        }),
        queryCache: new QueryCache({
          onError,
        }),
      })
  );
  const onClose = useCallback(() => setSnackbarOpen(false), [setSnackbarOpen]);
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ThemeProvider theme={theme}>
        <>
          <Routes>
            <Route element={<Layout />}>
              {mapToRoutes(sectionRoutes)}
              <Route path="*" element={<Navigate to="/customer" replace />} />
            </Route>
          </Routes>
          <Snackbar
            open={snackbarOpened}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Alert onClose={onClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>
        </>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
