import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Customer, useCreateStripeCustomer } from 'api/subscription';
import TextField from 'components/TextField';
import { Form, Formik, FormikProps } from 'formik';
import useValidateEmail from 'model/helper';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

type Prop = {
  domain: string;
};
export default function CustomerForm({ domain }: Prop) {
  const { t } = useTranslation();

  const customerFormModel = useRef<FormikProps<Customer>>(null);
  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    domain: '',
  };
  const { mutate: createStripeCustomer } = useCreateStripeCustomer();

  const { validate: validateEmail } = useValidateEmail();

  return (
    <Stack spacing={2}>
      <Formik
        innerRef={customerFormModel}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          createStripeCustomer(
            { ...values, domain },
            {
              onSuccess: (response) => {
                window.location.replace(response.data.stripeSessionUrl);
              },
              onError: () => {
                customerFormModel.current?.setSubmitting(false);
              },
            }
          );
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack spacing={4}>
              <Stack>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      key="firstName"
                      name="firstName"
                      label={t('customer.form.firstName')}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      key="lastName"
                      name="lastName"
                      label={t('customer.form.lastName')}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      key="email"
                      name="email"
                      label={t('customer.form.email')}
                      required
                      validate={(value: string) => validateEmail(value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      key="phoneNumber"
                      name="phoneNumber"
                      label={t('customer.form.phoneNumber')}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop={6}>
                    <LoadingButton
                      variant="contained"
                      loading={isSubmitting}
                      loadingPosition="start"
                      style={{ width: '100%', height: '50px' }}
                      startIcon={<SendIcon />}
                      type="submit"
                    >
                      {t('customer.form.continue')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
