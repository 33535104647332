import Subscription from './Subscription';
import SucessSubscriptionForm from './SucessSubscriptionForm/SucessSubscriptionForm';

export const routes = [
  { path: '/subscription' as const, component: Subscription, disabled: false },
  {
    path: '/confirmation' as const,
    component: SucessSubscriptionForm,
    disabled: false,
  },
];

export default routes;
