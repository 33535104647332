import { FullScreenLayout } from '@bravas/design-system';
import { Outlet } from 'react-router-dom';

function Layout() {
  return (
    <FullScreenLayout>
      <Outlet />
    </FullScreenLayout>
  );
}

export default Layout;
