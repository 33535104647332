import { createAxios as getAxios, validate } from '@bravas/js-commons-web';
import { Stripe } from 'stripe';
import {
  Customer,
  Environnements,
  SubscriptionData,
  Url,
  EnvironnementsSchema,
} from './types';

export const getEnvironnements = async () => {
  const { data } = await getAxios().get<{ data: Environnements }>(
    `/environnements/informations`
  );

  validate(EnvironnementsSchema, data.data);

  return data.data;
};

export const verifySubdomainAvailability = async (
  url: WithRequiredProperty<Url, 'subdomain'>
): Promise<{ data: boolean }> => {
  const { data } = await getAxios().post<{ data: boolean }>(
    `/subscription/domain-check`,
    url,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return data;
};

export const getStripeSession = async (stripeSessionId: string) => {
  const { data } = await getAxios().post<Stripe.Checkout.Session | null>(
    `/subscription/stripe-session`,
    { stripeSessionId },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return data;
};

export const deployBravasInstance = async (subscription: SubscriptionData) => {
  const result = await getAxios().post<{ id: string }>(
    `/subscription`,
    subscription,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result;
};

export const createStripeCustomer = async (customer: Customer) => {
  const result = await getAxios().post<{
    stripeSessionUrl: string;
  }>(`/stripe/create-customer`, customer, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result;
};
