import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';

import App from 'App';
import { BrowserRouter } from 'react-router-dom';
import initializeI18N from 'i18n';

function start() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const rootElement = document.getElementById('app-root')!;
  const root = ReactDOMClient.createRoot(rootElement);

  void initializeI18N();

  root.render(
    <StrictMode>
      <BrowserRouter basename={process.env.ASSET_PATH?.replace(/\/$/, '')}>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
}

start();
