import subscriptionRoutes from './subscription';
import customerRoutes from './customer';

const routes = {
  subscription: subscriptionRoutes,
  customer: customerRoutes,
};

export const routeSections = ['subscription', 'customer'] as const;
export type RouteSections = (typeof routeSections)[number];

export type RoutesKeys = keyof typeof routes;
export type Route = (typeof routes)[RoutesKeys][number];

export type RoutePath = Route['path'];

export default routes;
