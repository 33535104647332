import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import {
  SubscriptionForm as SubscriptionFormModel,
  useGetStripeSession,
} from 'api/subscription';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataPrivacy from 'components/DataPrivacy/DataPrivacy';
import Link from '@mui/material/Link';
import SubscriptionForm from './SubscriptionForm';

// STIPE Return url will be : http://client-portal-domain?session_id={CHECKOUT_SESSION_ID}
export default function Subscription() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const navigate = useNavigate();

  const {
    data: stripeSession,
    mutate: verifyStripeSession,
    isLoading,
  } = useGetStripeSession();

  useEffect(() => {
    if (sessionId) {
      verifyStripeSession(sessionId);
    }
  }, [sessionId, verifyStripeSession]);

  if (isLoading) {
    return (
      <Box>
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      </Box>
    );
  }

  const goToConfirmationPage = (values: SubscriptionFormModel) => {
    navigate('/confirmation', {
      state: { email: values.userEmail },
    });
  };

  return (
    <Box>
      <Stack spacing={4}>
        {sessionId && stripeSession && (
          <>
            <SubscriptionForm
              stripeSessionId={sessionId}
              domain={stripeSession.metadata?.domain || ''}
              onSuccess={goToConfirmationPage}
              userEmail={stripeSession.customer_details?.email || ''}
            />
            <DataPrivacy />
          </>
        )}
        {(!sessionId || !stripeSession) && (
          <Stack spacing={3} data-testId="error-stripe-session">
            <Typography variant="h1" whiteSpace="pre-line">
              {t('subscription.stripeSessionNotCorrect')}
            </Typography>
            <Typography variant="body2" whiteSpace="pre-line">
              {t('subscription.stripeSessionAccountAlreadyCreated')}
            </Typography>
            <Typography variant="body2" whiteSpace="pre-line">
              <Trans
                i18nKey="subscription.contactBravas"
                components={{
                  linkBravas: (
                    <Link
                      href="mailto:support@bravas.io"
                      title="Bravas"
                      color="#000000"
                      underline="none"
                      target="_blank"
                      fontWeight={700}
                    />
                  ),
                }}
              />
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
