import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from './locales/fr';
import en from './locales/en';

function initialize() {
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        fr,
        en,
      },
      ns: ['common', 'subscription', 'customer', 'ds'],
      fallbackLng: 'en',
      defaultNS: 'common',
      nsSeparator: '.',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
    });
}

export default initialize;
