import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  SubscriptionForm as SubscriptionFormModel,
  useDeployBravasInstance,
  useGetEnvironnements,
  useVerifySubdomainAvailability,
} from 'api/subscription';
import TextField, { labelProps } from 'components/TextField';
import { Form, Formik, FormikProps } from 'formik';
import useValidateEmail from 'model/helper';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const DEFAULT_VALUES = {
  companyName: '',
  subdomain: '',
  userEmail: '',
  domain: '',
  locale: '',
};
// TODO: issue seen, when using setFieldError, the error appear the first time but then disapear inside the errors object of the form... why ????
type Prop = {
  onSuccess?: (values: SubscriptionFormModel) => void;
  stripeSessionId: string;
  userEmail?: string;
  domain: string;
};
export default function SubscriptionForm({
  onSuccess,
  stripeSessionId,
  userEmail,
  domain,
}: Prop) {
  const { t } = useTranslation();
  const { validate: validateEmail } = useValidateEmail();

  const { data: environnements } = useGetEnvironnements();

  const { i18n } = useTranslation();

  const {
    mutate: verifyDomainAvailability,
    data: domainAvailability,
    isLoading: verifyDomainAvailabilityIsLoading,
  } = useVerifySubdomainAvailability();
  const { mutate: deployBravasInstance } = useDeployBravasInstance();

  const subscriptionFormModel =
    useRef<FormikProps<SubscriptionFormModel>>(null);

  const [initialValues] = useState<SubscriptionFormModel>({
    ...DEFAULT_VALUES,
    userEmail: userEmail || '',
  });

  const validateSubdomain = (value: string): string => {
    if (!value) {
      return '';
    }
    const regex = /^[a-z][a-z0-9]+$/;
    if (!regex.test(value)) {
      return t('subscription.form.error.subdomainFormat');
    }
    if (
      environnements &&
      environnements.forbiddenWords.some((word) => word === value)
    ) {
      return t('subscription.form.error.forbiddenWords', {
        words: environnements.forbiddenWords.join(', '),
      });
    }
    return '';
  };

  const validateSubdomainAvailable = (
    subdomain = subscriptionFormModel.current?.values.subdomain
  ) => {
    if (subdomain && validateSubdomain(subdomain).length === 0) {
      verifyDomainAvailability({
        subdomain,
      });
    }
  };

  return (
    <Stack spacing={2}>
      <Formik
        innerRef={subscriptionFormModel}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          deployBravasInstance(
            { ...values, stripeSessionId, locale: i18n.language },
            {
              onSuccess: () => {
                if (onSuccess !== undefined) {
                  onSuccess(values);
                }
              },
              onError: () => {
                subscriptionFormModel.current?.setSubmitting(false);
              },
            }
          );
        }}
      >
        {({ isSubmitting, values, errors, setFieldValue, touched }) => (
          <Form>
            <Stack spacing={4}>
              <Stack>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      key="companyName"
                      name="companyName"
                      label={t('subscription.form.companyName')}
                      required
                      error={!values.companyName}
                      onKeyUp={(e) => {
                        if (!touched.subdomain) {
                          const subdomainValue = (
                            e.target as HTMLTextAreaElement
                          ).value
                            .toLowerCase()
                            .replace(/[^A-Z0-9]+/gi, '');
                          setFieldValue('subdomain', subdomainValue);
                          validateSubdomainAvailable(subdomainValue);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      key="subdomain"
                      name="subdomain"
                      helperText={t('subscription.form.subdomainHelper')}
                      label={t('subscription.form.subdomain')}
                      required
                      error={
                        (!verifyDomainAvailabilityIsLoading &&
                          domainAvailability?.data === false) ||
                        Boolean(errors.subdomain) ||
                        !values.subdomain
                      }
                      inputProps={{
                        onChange: (
                          e: React.ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => validateSubdomainAvailable(e.target.value),
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          https://
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{
                            position: 'absolute',
                            marginLeft: 100 + values.subdomain.length * 8,
                          }}
                        >
                          .{domain}
                        </InputAdornment>
                      }
                      validate={(value: string) => validateSubdomain(value)}
                      InputLabelProps={labelProps}
                    />
                    {domainAvailability?.data === false && (
                      <Typography
                        variant="body2"
                        style={{ fontSize: '1.125rem', color: '#d32f2f' }}
                        data-testid="subdomainTaken"
                      >
                        {t('subscription.form.error.subdomainTaken')}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      key="userEmail"
                      name="userEmail"
                      label={t('subscription.form.userEmail')}
                      required
                      validate={(value: string) => validateEmail(value)}
                      error={!values.userEmail || Boolean(errors.userEmail)}
                      InputLabelProps={labelProps}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    textAlign="center"
                    alignItems="center"
                    spacing={1}
                    marginTop={2}
                  >
                    <Checkbox required name="cgu" data-testid="cgu" />
                    <Typography variant="body2" whiteSpace="pre-line">
                      <Trans
                        i18nKey="common.cgu"
                        components={{
                          linkHere: (
                            <Link
                              href="https://www.bravas.io/legales/mentions-legales"
                              title="Bravas"
                              target="_blank"
                            />
                          ),
                        }}
                      />
                    </Typography>
                  </Stack>
                </Grid>
                <Stack marginTop={4} alignItems="center">
                  <LoadingButton
                    variant="contained"
                    loading={isSubmitting}
                    loadingPosition="start"
                    style={{ width: '100%', height: '50px' }}
                    disabled={
                      verifyDomainAvailabilityIsLoading ||
                      domainAvailability?.data === false
                    }
                    startIcon={<SendIcon />}
                    type="submit"
                  >
                    {t('subscription.form.continue')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
SubscriptionForm.defaultProps = {
  onSuccess: () => {},
  userEmail: '',
};
