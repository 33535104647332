import { z } from 'zod';

export const CustomerSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  domain: z.string(),
});
export type Customer = z.infer<typeof CustomerSchema>;

export const EnvironnementSchema = z.object({
  name: z.string(),
  title: z.string(),
  description: z.string(),
  overwatchUrl: z.string(),
  stripePriceItemId: z.string(),
});
export type Environnement = z.infer<typeof EnvironnementSchema>;

export const EnvironnementsSchema = z.object({
  forbiddenWords: z.array(z.string()),
  environnements: z.array(EnvironnementSchema),
});
export type Environnements = z.infer<typeof EnvironnementsSchema>;

export const UrlSchema = z.object({
  subdomain: z.string(),
});
export type Url = z.infer<typeof UrlSchema>;

export const SubscriptionFormSchema = z.object({
  companyName: z.string(),
  subdomain: z.string(),
  userEmail: z.string(),
  locale: z.string(),
});
export type SubscriptionForm = z.infer<typeof SubscriptionFormSchema>;

export const SubscriptionDataSchema = SubscriptionFormSchema.extend({
  stripeSessionId: z.string(),
});
export type SubscriptionData = z.infer<typeof SubscriptionDataSchema>;
