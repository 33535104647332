import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Trans } from 'react-i18next';

export default function DataPrivacy() {
  return (
    <Box mb={6} mt={4}>
      <Typography
        variant="body2"
        whiteSpace="pre-line"
        fontSize="12px!important"
      >
        <Trans
          i18nKey="common.dataPolicy"
          components={{
            linkHere: (
              <Link
                href="https://www.bravas.io/legales/privacy"
                title="Bravas"
                target="_blank"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );
}
