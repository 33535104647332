import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/system';
import { deepmerge } from '@mui/utils';
import {
  dsthemeOptions,
  dsbreakpoints,
  dspxToRem,
} from '@bravas/design-system';

export const themeOptions: ThemeOptions = {
  palette: { secondary: { dark: '#fcf8f5' } },
  typography: {
    h1: {
      fontSize: dspxToRem(32),
      [`@media (min-width:${dsbreakpoints.desktop}px)`]: {
        fontSize: dspxToRem(36),
      },
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: dspxToRem(1),
      color: '#000',
      // fontFamily: 'Sora',
    },
    body2: {
      fontSize: dspxToRem(12),
      [`@media (min-width:${dsbreakpoints.desktop}px)`]: {
        fontSize: dspxToRem(14),
      },
      lineHeight: 1.5,
      marginBottom: 2,
      color: '#535c68',
    },
  },
};
const theme = createTheme(deepmerge(dsthemeOptions, themeOptions));
export default theme;
