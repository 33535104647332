import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function SucessSubscriptionForm() {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const email = useLocation()?.state?.email as string;
  return (
    <Stack
      direction="column"
      spacing={3}
      data-testid="success-subscription-form"
    >
      <Typography variant="h1" whiteSpace="pre-line">
        {t('subscription.form.success')}
      </Typography>
      <Typography
        variant="body2"
        whiteSpace="pre-line"
        fontWeight={700}
        color="black"
      >
        {t('subscription.form.sucessEmail', { email })}
      </Typography>
      <Typography variant="body2" whiteSpace="pre-line">
        {t('subscription.form.successInformation')}
      </Typography>
      <Typography variant="body2" whiteSpace="pre-line">
        <Trans
          i18nKey="subscription.form.successQuestion"
          components={{
            linkBravas: (
              <Link
                href="https://www.bravas.io/"
                title="Bravas"
                color="#000000"
                underline="none"
                target="_blank"
                fontWeight={700}
              />
            ),
          }}
        />
      </Typography>
    </Stack>
  );
}
