import { useTranslation } from 'react-i18next';

const useValidateEmail = (): { validate: (value: string) => string } => {
  const { t } = useTranslation();
  return {
    validate: (value: string) => {
      let errorMessage = '';
      if (value === undefined || !value) {
        return errorMessage;
      }
      const regex = /^\S+@\S+\.\S+$/;
      if (value.length > 0 && !regex.test(value)) {
        errorMessage = t('common.form.error.emailFormat');
      }
      return errorMessage;
    },
  };
};
export default useValidateEmail;
