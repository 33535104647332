import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createStripeCustomer,
  deployBravasInstance,
  getEnvironnements,
  getStripeSession,
  verifySubdomainAvailability,
} from './queries';
import { Environnement } from './types';

export const environnementCacheKeys = {
  all: ['environnements'] as const,
  lists: () => [...environnementCacheKeys.all, 'list'] as const,
  environnements: () =>
    [...environnementCacheKeys.all, 'environnement'] as const,
  environnement: (name: string) =>
    [...environnementCacheKeys.environnements(), name] as const,
  session: () => [...environnementCacheKeys.all] as const,
};

export function useGetEnvironnements() {
  const queryClient = useQueryClient();

  return useQuery(environnementCacheKeys.all, getEnvironnements, {
    onSuccess: (data) => {
      data?.environnements.forEach((environnement: Environnement) => {
        queryClient.setQueryData(
          environnementCacheKeys.environnement(environnement.name),
          environnement
        );
      });
    },
  });
}

export function useVerifySubdomainAvailability() {
  return useMutation(verifySubdomainAvailability);
}
export function useDeployBravasInstance() {
  return useMutation(deployBravasInstance);
}

export function useGetStripeSession() {
  return useMutation(getStripeSession);
}

export function useCreateStripeCustomer() {
  return useMutation(createStripeCustomer);
}
