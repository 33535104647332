import { en as dsen } from '@bravas/design-system';
import common from './common.json';
import subscription from './subscription.json';
import customer from './customer.json';

const en = {
  common,
  subscription,
  customer,
  ds: {
    ...dsen.ds,
  },
};

export default en;
